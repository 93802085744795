import React, { useContext, useEffect, useRef, useState, useCallback } from 'react';
import { WelletContext } from '../../../context/WelletContext';
import { CircleSpinner } from 'react-spinners-kit';
import { useDispatch, useSelector } from 'react-redux';
import { getQueryString } from '../../../helpers/url';
import { generateDisabledDates, getLocale } from '../../../helpers/dates';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { setReservation, setReservationType } from '../../../actions/reservationActions';
import Header from '../../../components/Common/Header/Header';
import ContentApp from '../../../components/ContentApp';
import EmptyActivity from '../../../components/Common/EmptyActivity/EmptyActivity';
import Calendar from '../../../components/Common/Calendar/Calendar';
import ErrorBox from '../../../components/Common/ErrorBox/ErrorBox';
import ReservationTickets from '../../../components/ReservationTickets/ReservationTickets';
import ReservationTicketInfo from '../ReservationTicketInfo/ReservationTicketInfo';
import ProductModal from '../../../components/Common/ProductModal/ProductModal';
import styles from '../reservation.module.css';
import QuickPinchZoom, { make3dTransformValue } from 'react-quick-pinch-zoom';
import ButtonReservation from '../../../components/Common/Button/ButtonReservation';

import Money from '../../../components/Common/Money/Money';
import ChevronIcon from '../../../components/icons/chevronIcon';
import CalendarReservationIcon from '../../../components/icons/CalendarReservationIcon';
import WarningBox from '../../../components/Common/WarningBox/WarningBox';

import '../../../css/product-list2.css';
import {
  addExtraProduct,
  removeExtraProduct,
  setExtraProductList,
} from '../../../actions/extraProductActions';

const ReservationArea = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const welletContext = useContext(WelletContext);
  const history = useHistory();
  const { reservationType } = useParams();
  const language = useSelector((state) => state.app.language);
  const locale = getLocale(language);
  const showId = getQueryString('showId');
  const refReservationId = getQueryString('refReservationId');
  const refReferenceCode = getQueryString('refReferenceCode');
  const canBook = useSelector((state) => state.associates.roles).includes('associate');
  const date = useSelector((state) => state.reservation.date);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [show, setShow] = useState(true);
  const [today, setToday] = useState(null);
  const [compareToday, setCompareToday] = useState(null);
  const [minDate, setMinDate] = useState(new Date());
  const [disabledDates, setDisabledDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [calendarVisible, setCalendarVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [products, setProducts] = useState([]);
  const extraProducts = useSelector((state) => state.extraProducts.items);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showMap, setShowMap] = useState(false);
  const imgRef = useRef();
  const [hasCallCenter, setHasCallCenter] = useState(null);
  const onUpdate = useCallback(({ x, y, scale }) => {
    const { current: img } = imgRef;

    if (img) {
      const value = make3dTransformValue({ x, y, scale });

      img.style.setProperty('transform', value);
    }
  }, []);

  const getProducts = async (date) => {
    setLoadingProducts(true);
    setError(null);
    try {
      const result = await welletContext.apis.tickets.get(
        `/products/get?showid=${showId}&date=${date}&lang=${language}&type=${reservationType}`,
      );
      const initializedProducts = result.data.products.map((product) => ({
        ...product,
        quantity: 0,
        disabled: false,
      }));
      setProducts(initializedProducts);
      dispatch(setExtraProductList(result.data.extraProducts));

      if (initializedProducts.length === 0) setError(t('productsNotAvailables'));

      setLoadingProducts(false);
    } catch (error) {
      if (error.response && error.response.data) {
        setError(t('reservationNotAvailables'));
      } else {
        setError(t('callApiError'));
      }
      console.error(error);
    }
  };

  const getShow = async () => {
    setLoading(true);
    try {
      const result = await welletContext.apis.tickets.get(`/shows/get/`, {
        params: {
          showId: showId,
          lang: language,
          reservationType,
        },
      });

      setShow(result.data);
      const todayDate = new Date(result.data.calendar.minDate);

      const yesterdayDate = new Date(todayDate);
      yesterdayDate.setDate(yesterdayDate.getDate() - 1);
      const firstDate = new Date(todayDate.getFullYear(), todayDate.getMonth(), 1);
      setToday(todayDate);
      setCompareToday(todayDate);
      setMinDate(todayDate);
      setDisabledDates(generateDisabledDates(firstDate, yesterdayDate, locale));

      setSelectedDate(getDate(todayDate));

      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const getDate = (todayDate) => {
    let result;

    if (date !== null) {
      result = new Date(date + 'T00:00:00');

      if (result < todayDate) result = todayDate;

      return result;
    }

    return todayDate;
  };

  useEffect(() => {
    if (show && show.reservationTypes && Array.isArray(show.reservationTypes)) {
      const reservation = show.reservationTypes.find(
        (item) => item.type.trim().toLowerCase() === reservationType.trim().toLowerCase(),
      );

      if (reservation) {
        const hasCallCenter = reservation.hasCallCenter;
        setHasCallCenter(hasCallCenter);

        dispatch(setReservationType(reservationType));
      }
    }
  }, [show]);

  useEffect(() => {
    getShow();
  }, []);

  useEffect(() => {
    if (today !== null) getProducts(format(selectedDate, 'yyyy-MM-dd'));
  }, [today]);

  const handleChangeDate = async (day) => {
    if (day !== undefined) {
      setProducts([]);
      setSelectedDate(day);
      getProducts(format(day, 'yyyy-MM-dd'));
    }
    setCalendarVisible(!calendarVisible);
  };

  const handleAddProduct = (productId) => {
    const index = products.findIndex((p) => p.id === productId);
    handleIncrement(index);
  };

  const handleIncrement = (index) => {
    const product = products[index];

    if(product.stock && product.stock === product.quantity)
      return;

    let newProducts = [...products];
    if (product.isExclusive) {
      newProducts
        .filter((p) => p.isExclusive && p.id !== product.id)
        .forEach((p) => (p.disabled = true));
    }

    newProducts[index].disabled = false;
    newProducts[index].quantity = newProducts[index].stock
      ? Math.min(newProducts[index].stock, newProducts[index].quantity + 1)
      : newProducts[index].quantity + 1;

    setProducts(newProducts);

    if (product.extraPax > 0) {
      dispatch(addExtraProduct({ ...product, quantity: 0, specialType: 'EXTRAPAX' }));
    }
  };

  const handleDecrement = (index) => {
    let newProducts = [...products];
    newProducts[index].quantity = Math.max(0, newProducts[index].quantity - 1);

    if (newProducts[index].isExclusive && newProducts[index].quantity === 0) {
      newProducts.forEach((p) => (p.disabled = false));
    }
    dispatch(removeExtraProduct({ ...newProducts[index], quantity: 0, specialType: 'EXTRAPAX' }));
    setProducts(newProducts);
  };

  const sumTotal = () => {
    return products
      .filter((p) => p.quantity > 0)
      .reduce((total, p) => total + p.prices.price * p.quantity, 0);
  };

  const totalConsume = () => {
    return products
      .filter((p) => p.quantity > 0)
      .reduce((total, p) => total + p.prices.consumption * p.quantity, 0);
  };

  const totalPax = () => {
    return products
      .filter((p) => p.quantity > 0)
      .reduce((total, p) => total + p.pax * p.quantity, 0);
  };

  const hasItems = products?.some((p) => p.quantity > 0);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const setReservationInfo = () => {
    let finalProducts = [];

    products
      .filter((product) => product.quantity > 0)
      .map((product) => {
        finalProducts.push({
          id: product.id,
          quantity: product.quantity,
          extraPaxs: 0,
          specialType: product.specialType,
        });
      });

    const reservationInfo = {
      showId: showId,
      showName: show.name,
      products: finalProducts,
      date: format(selectedDate, 'yyyy-MM-dd'),
      callCenter: hasCallCenter,
    };

    return reservationInfo;
  };

  const handleContinue = () => {
    const reservationInfo = setReservationInfo();

    let query = `?showId=${showId}&showName=${encodeURIComponent(show.name)}`;
    if (refReservationId) query += `&refReservationId=${refReservationId}`;

    dispatch(setReservation(reservationInfo));
    if (canBook) {
      if (extraProducts.length > 0) {
        history.push({
          pathname: '/area-extra-reservation',
          search: query,
        });
      } else {
        history.push({
          pathname: '/reservation-check',
          search: query,
        });
      }
    } else {
      setErrorMessage(t('cantBook'));
    }
  };

  const handleLinkMedia = () => {
    history.push({
      pathname: '/social-media',
      search: `?showid=${showId}`,
    });
  };

  return loading ? (
    <div className='loaderDiv'>
      <CircleSpinner
        className='spinner-app mx-auto'
        style={{ margin: 'auto' }}
        size={40}
        color='var(--color-font-primary)'
      />
    </div>
  ) : (
    <>
      <ProductModal
        open={calendarVisible}
        fullScreen
        animationClass='fade-up-horizontal'
        hasBackground
        showCloseButton={false}
      >
        <Header onClick={() => setCalendarVisible(false)} showBack />
        <Calendar
          minDate={minDate}
          disabledDates={disabledDates}
          onSelectDate={handleChangeDate}
          selectedDate={selectedDate}
        />
      </ProductModal>

      <Header title={<div className='text-capitalize px-3'>{t('book') + ' ' + show.name}</div>} />
      <ContentApp classes='reservation' menu={true}>
        {compareToday !== null && selectedDate ? (
          <>
            <div>
              {refReferenceCode && (
                <div className='mb-3'>
                  <WarningBox
                    title={t('modifyAlertTitle')}
                    subtitle={t('modifyAlertText', { code: refReferenceCode, show: show.name })}
                  />
                </div>
              )}
              <span className='text-secondary bold'>{t('reservationDate')}</span>
              <div
                className='font-medium sentences-capitalize fw-bold mt-1'
                onClick={setCalendarVisible}
              >
                {locale.code === 'es'
                  ? format(selectedDate, "EEEE, d 'de' MMMM", { locale })
                  : format(selectedDate, 'EEEE, d MMMM', { locale })}
                <CalendarReservationIcon
                  size={'1.5rem'}
                  color={'var(--color-primary)'}
                  styles={{ marginLeft: '0.5rem', marginTop: '-2px' }}
                />
              </div>
            </div>

            <div className='row mt-4'>
              <div className='col bold'>
                {reservationType === 'area' ? t('reservationarea') : t('selectMenu')}
              </div>
              <div
                className={`font-tiny col-auto ${
                  show?.seatDistributionImgUrl && reservationType === 'area' ? '' : 'd-none'
                }`}
                onClick={() => setShowMap(!showMap)}
              >
                <div className='d-flex align-items-center'>
                  {showMap ? (
                    <>
                      {t('hideMap')}
                      <span className='view-more'>-</span>
                    </>
                  ) : (
                    <>
                      {t('viewMap')}
                      <span className='view-more'>+</span>
                    </>
                  )}
                </div>
              </div>
            </div>
            {error ? (
              <div className='mt-3'>
                <EmptyActivity subtitle={error} />
              </div>
            ) : (
              !loading && (
                <>
                  <div className={`mt-2 fade-up ${showMap ? 'd-block' : 'd-none'}`}>
                    <div className='image-map'>
                      <QuickPinchZoom onUpdate={onUpdate} centerContained={true}>
                        <img ref={imgRef} src={show.seatDistributionImgUrl} alt='map' />
                      </QuickPinchZoom>
                    </div>
                  </div>
                  <div className='mt-3'>
                    <ReservationTickets
                      loading={loadingProducts}
                      products={products}
                      handleIncrement={handleIncrement}
                      handleDecrement={handleDecrement}
                      setSelectedProduct={setSelectedProduct}
                      toggleModal={toggleModal}
                    />
                  </div>
                </>
              )
            )}

            {errorMessage && <ErrorBox title={errorMessage} />}
            {hasItems && (
              <div
                className='position-fixed w-100'
                style={{
                  bottom: 0,
                  left: 0,
                  zIndex: 2,
                }}
              >
                <div className='container'>
                  <div className='row justify-content-center'>
                    <div className='col-12 col-md-7 col-lg-6 p-0'>
                      <ButtonReservation
                        leftContent={
                          <>
                            <div className='font-normal'>
                              {t('total')} <Money value={sumTotal()} />
                            </div>
                            <div className='font-smaller'>
                              {reservationType.toUpperCase() === 'AREA'
                                ? t('upToGuests', { pax: totalPax() })
                                : t('person', { count: totalPax() })}
                            </div>
                            {totalConsume() > 0 && (
                              <div className='font-smaller'>
                                {t('consumptionCredit')} <Money value={totalConsume()} />
                              </div>
                            )}
                          </>
                        }
                        rightContent={
                          <div>
                            {t('continue')}
                            <span>
                              <ChevronIcon size={20} fillColor={'#000'} />
                            </span>
                          </div>
                        }
                        size='large'
                        className={`${styles.btn}`}
                        onClick={handleContinue}
                        fixed={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        ) : null}
      </ContentApp>

      <ProductModal
        open={isModalOpen}
        onClose={toggleModal}
        animationClass='slide-left-right'
        fullScreen={true}
        hasBackground={true}
        backIcon={true}
      >
        <ReservationTicketInfo
          productId={selectedProduct?.id}
          showAddButton={
            selectedProduct?.stock === null ||
            (!selectedProduct?.hasSoldOut && selectedProduct?.stock > 0)
          }
          handleIncrement={() => handleAddProduct(selectedProduct?.id)}
          prices={selectedProduct?.prices}
          toggleModal={toggleModal}
          isModalOpen={isModalOpen}
        />
      </ProductModal>
    </>
  );
};

export default ReservationArea;
